import envValues from '../enviornment';

export const SERVER_URL = `${envValues.REACT_APP_API_ENDPOINT}/auth`;
export const SERVER_BASE_URL = envValues.REACT_APP_API_ENDPOINT;
export const ERROR_MESSAGE = 'Api Fetch Error!';
export const ENCRYPT_SECERET_KEY = 'armia-secret-key@123';
export const REACT_APP_API_POST_USER = `${envValues.REACT_APP_API_ENDPOINT}/user`;
export const REACT_APP_API_FETCH_USER = `${envValues.REACT_APP_API_ENDPOINT}/user?`;
export const REACT_APP_API_FETCH_EMPLOYEE = `${envValues.REACT_APP_API_ENDPOINT}/employee?active=true&`;
export const REACT_APP_API_FETCH_ROLE = `${envValues.REACT_APP_API_ENDPOINT}/roles?active=true&`;
export const REACT_APP_API_SKILLS = `${envValues.REACT_APP_API_ENDPOINT}/dummy/selectdata`;
export const REACT_APP_API_ROLES_DROPDOWN = `${envValues.REACT_APP_API_ENDPOINT}/roles/select`;
export const REACT_APP_API_CATEGORY_DROPDOWN = `${envValues.REACT_APP_API_ENDPOINT}/category/select`;
export const REACT_APP_API_FETCH_NOTIFICATIONS = `${envValues.REACT_APP_API_ENDPOINT}/notifications?`;
export const REACT_APP_API_FETCH_COUPONS =`${envValues.REACT_APP_API_ENDPOINT}/coupons?`;
export const REACT_APP_API_FETCH_APPUSER = `${envValues.REACT_APP_API_ENDPOINT}/appuser?isDeleted=false&`;
export const REACT_APP_API_FETCH_PUBLISHMEDIA = `${envValues.REACT_APP_API_ENDPOINT}/publishmedia?populate=user,adminuser&type=image&type=audio&type=video&`;
export const REACT_APP_API_FETCH_TOPCULTURES = `${envValues.REACT_APP_API_ENDPOINT}/topculture?`;
export const REACT_APP_API_FETCH_PUBLISHARTICLE = `${envValues.REACT_APP_API_ENDPOINT}/publishmedia?populate=user,adminuser,category&type=article&`;
export const REACT_APP_API_POST_GET_FEED = `${envValues.REACT_APP_API_ENDPOINT}/post`;
export const REACT_APP_API_FETCH_REPORTEDCONTENTS = `${envValues.REACT_APP_API_ENDPOINT}/report?populate=user,reported_user,commentId,postId,postId.adminuser&`;
export const REACT_APP_API_FETCH_REPORTEDCONTENTSHISTORY = `${envValues.REACT_APP_API_ENDPOINT}/report/history?populate=user,reported_user,commentId,postId&`;
export const REACT_APP_API_FETCH_CATEGORY =`${envValues.REACT_APP_API_ENDPOINT}/category?`;
export const REACT_APP_API_FETCH_SUBCATEGORY =`${envValues.REACT_APP_API_ENDPOINT}/category/sub?`;
export const REACT_APP_API_FETCH_CONTACT = `${envValues.REACT_APP_API_ENDPOINT}/contact?isDeleted=false&sortBy=createdAt:desc&`;



