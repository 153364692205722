import React, { Suspense, lazy } from 'react';
import { SpinnerDotted } from 'spinners-react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Outerlayout from '../layouts/outerLayout';
import Innerlayout from '../layouts/innerLayout';
import { getCookies, removeCookies } from '../hooks/useCookies';
import Settings from '../pages/settings/settings';
import ViewUsers from '../pages/users/viewUser';
import UserEdit from '../pages/users/editUser';
// import UserIndex from '../pages/users/index';
import UserCreate from '../pages/users/createUser';
import Preferences from '../pages/settings/preferences';
import CreateRoles from '../pages/roles/createRoles';
import RolesIndex from '../pages/roles/index';
import EditRoles from '../pages/roles/editRoles';
import SettingsApi from '../pages/settings/SettingsApiPage';
import SettingsLayout from '../pages/settings/settingsLayout';
import PageNotFound from '../pages/404/pageNotFound';
import PermissionDenied from '../pages/404/pageNotFound';
import ProfileLayout from '../pages/profile/profileLayout';

const Login = lazy(() => import('../pages/logIn/logIn'));
const UserIndex = lazy(() => import('../pages/users/index'));
const UserVerification = lazy(() => import('../pages/verify/userVerfication'));
const Register = lazy(() => import('../pages/register/register'));
const Forgetpassword = lazy(() => import('../pages/passwords/forgetPassword'));
const ResetPassword = lazy(() => import('../pages/passwords/resetPassword'));
const Dashboard = lazy(() => import('../pages/dashboard/dashboard'));
const HooksTest = lazy(() => import('../components/hooksFormTest'));
const CustomHooksTest = lazy(() => import('../hooks/customHooksTest'));
const MyForm = lazy(() => import('../components/PhoneNumberBox/PhoneNumberBox'));
const AgGridTable = lazy(() => import('../components/AgTable/AgGridTable'));
// const PageNotFound = lazy(() => import('../pages/404/pageNotFound'));
const ProfilePage = lazy(() => import('../pages/profile/profilepage'));
const ChangePassword = lazy(() => import('../pages/profile/changePassword'));
const ProfileEdit = lazy(() => import('../pages/profile/profileEdits'));
const Faq = lazy(() => import('../cms/faq'));
const ViewRole = lazy(() => import('../pages/roles/view'));
const CouponsIndex = lazy(() => import('../pages/coupons/index'));
const CouponsCreate = lazy(() => import('../pages/coupons/create'));
const ViewCoupon = lazy(() => import('../pages/coupons/view'));
const CouponEdit = lazy(() => import('../pages/coupons/edit'));
const AppUserIndex = lazy(() => import('../pages/appusers/index'));
const ViewAppUser = lazy(() => import('../pages/appusers/viewUser'));
const EditAppUser = lazy(() => import('../pages/appusers/editUser'));
const ReportsIndex = lazy(() => import('../pages/reports/index'));
const PublishArticleIndex = lazy(() => import('../pages/publisharticle/index'));
const PublishArticleCreate = lazy(() => import('../pages/publisharticle/create'));
const PublishArticleEdit = lazy(() => import('../pages/publisharticle/edit'));
const ViewPublishArticle = lazy(() => import('../pages/publisharticle/view'));
const TopCultureIndex = lazy(() => import('../pages/topcultures/index'));
const TopCultureCreate = lazy(() => import('../pages/topcultures/create'));
const TopCultureEdit = lazy(() => import('../pages/topcultures/edit'));
const ViewTopCulture = lazy(() => import('../pages/topcultures/view'));
const PublishMediaIndex = lazy(() => import('../pages/publishmedia/index'));
const PublishMediaCreate = lazy(() => import('../pages/publishmedia/create'));
const ViewPublishMedia = lazy(() => import('../pages/publishmedia/view'));
const PublishMediaEdit = lazy(() => import('../pages/publishmedia/edit'));
const ViewReportedRequests = lazy(() => import('../pages/reports/viewReportedRequests'));
const ReportHistoryIndex = lazy(() => import('../pages/reporthistory/index'));
const ViewReportedHistory = lazy(() => import('../pages/reporthistory/viewReportedHistory'));
const CategoryIndex = lazy(() => import('../pages/category/index'));
const CategoryCreate = lazy(() => import('../pages/category/create'));
const CategoryEdit = lazy(() => import('../pages/category/edit'));
const ViewCategory = lazy(() => import('../pages/category/view'));






const NotificationsIndex = lazy(() => import('../pages/notifications/index'));
export default function Routers() {
  return (
    <Router>
      <Routes>
        <Route element={<Outerlayout />}>
          <Route
            exact
            path="/"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        left: '50%',
                        position: 'absolute',
                        textAlign: 'center',
                        top: '50%',
                        color: '#667BBC',
                      }}
                    />
                  </div>
                }
              >
                <UnProtectedRoute>
                  {' '}
                  <Login />
                </UnProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/login"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        left: '50%',
                        position: 'absolute',
                        textAlign: 'center',
                        top: '50%',
                        color: '#667BBC',
                      }}
                    />
                  </div>
                }
              >
                <UnProtectedRoute>
                  {' '}
                  <Login />
                </UnProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/verify-email"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#667BBC' }}
                    />
                  </div>
                }
              >
                <UnProtectedRoute>
                  {' '}
                  <UserVerification />
                </UnProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/register"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        left: '50%',
                        position: 'absolute',
                        textAlign: 'center',
                        top: '50%',
                        color: '#667BBC',
                      }}
                    />
                  </div>
                }
              >
                <UnProtectedRoute>
                  <Register />
                </UnProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/forgetpassword"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        left: '50%',
                        position: 'absolute',
                        textAlign: 'center',
                        top: '50%',
                        color: '#667BBC',
                      }}
                    />
                  </div>
                }
              >
                <UnProtectedRoute>
                  <Forgetpassword />
                </UnProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/reset/:id"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#667BBC' }}
                    />
                  </div>
                }
              >
                <UnProtectedRoute>
                  {' '}
                  <ResetPassword />
                </UnProtectedRoute>
              </Suspense>
            }
          />
          {/* <Route exact path="/" element={<Dashboard />} /> */}
          <Route
            exact
            path="/reset"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#667BBC' }}
                    />
                  </div>
                }
              >
                <UnProtectedRoute>
                  {' '}
                  <ResetPassword />
                </UnProtectedRoute>
              </Suspense>
            }
          />
        </Route>
        <Route element={<Innerlayout />}>
          <Route
            exact
            path="/roles"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#667BBC' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <RolesIndex />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/roles/create"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#667BBC' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <CreateRoles />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/roles/edit/:roleId"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#667BBC' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <EditRoles />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/roles/viewdetails/:roleId"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#667BBC' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <ViewRole />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/dashboard"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#667BBC' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <Dashboard />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/notifications"
            element={
              <Suspense
                fallback={
                  <div style={{ textAlign: 'center', marginTop: '400px', color: '#667BBC' }}>
                    <SpinnerDotted />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <NotificationsIndex />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/coupons"
            element={
              <Suspense
                fallback={
                  <div style={{ textAlign: 'center', marginTop: '400px', color: '#667BBC' }}>
                    <SpinnerDotted />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <CouponsIndex />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/coupons/create"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#667BBC' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <CouponsCreate />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/coupons/viewdetails/:couponId"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#667BBC' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <ViewCoupon />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            path="/coupons/edit/:couponId"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#667BBC' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <CouponEdit />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/category"
            element={
              <Suspense
                fallback={
                  <div style={{ textAlign: 'center', marginTop: '400px', color: '#667BBC' }}>
                    <SpinnerDotted />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <CategoryIndex />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/category/create"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#667BBC' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <CategoryCreate />
                </ProtectedRoute>
              </Suspense>
            }
          />
            <Route
            path="/category/edit/:categoryId"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#667BBC' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <CategoryEdit />
                </ProtectedRoute>
              </Suspense>
            }
          />
           <Route
            exact
            path="/category/viewdetails/:categoryId"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#667BBC' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <ViewCategory />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/user/viewdetails/:userId"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#667BBC' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <ViewUsers />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/user/create"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#667BBC' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <UserCreate />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/user/edit/:userId"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#667BBC' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <UserEdit />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/hooks"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#667BBC' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <HooksTest />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/customhooks"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#667BBC' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <CustomHooksTest />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/text"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#667BBC' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <MyForm />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/table"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#667BBC' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <AgGridTable />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/user"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#667BBC' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <UserIndex />
                </ProtectedRoute>
              </Suspense>
            }
          />
           <Route
            exact
            path="/appuser"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#667BBC' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <AppUserIndex />
                </ProtectedRoute>
              </Suspense>
            }
          />
           <Route
            exact
            path="/appuser/viewdetails/:userId"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#667BBC' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <ViewAppUser />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            path="/appuser/edit/:userId"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#667BBC' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <EditAppUser />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/PublishArticle"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#667BBC' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <PublishArticleIndex/>
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/PublishArticle/create"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#667BBC' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <PublishArticleCreate />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            path="/PublishArticle/edit/:userId"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#667BBC' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <PublishArticleEdit />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/PublishArticle/viewdetails/:publishmediaId"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#667BBC' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <ViewPublishArticle />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/topculture"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#667BBC' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <TopCultureIndex />
                </ProtectedRoute>
              </Suspense>
            }
          />
           <Route
            exact
            path="/topculture/create"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#667BBC' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <TopCultureCreate />
                </ProtectedRoute>
              </Suspense>
            }
          />
           <Route
            exact
            path="/topculture/viewdetails/:topcultureId"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#667BBC' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <ViewTopCulture />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            path="/topculture/edit/:topcultureId"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#667BBC' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <TopCultureEdit />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/publishmedia"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#667BBC' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <PublishMediaIndex />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/publishmedia/create"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#667BBC' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <PublishMediaCreate />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/publishmedia/viewdetails/:publishmediaId"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#667BBC' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <ViewPublishMedia />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            path="/publishmedia/edit/:userId"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#667BBC' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <PublishMediaEdit />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/reportedrequests"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#667BBC' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <ReportsIndex />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/reportedrequests/viewdetails/:reportId"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#667BBC' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <ViewReportedRequests />
                </ProtectedRoute>
              </Suspense>
            }
          />
           <Route
            exact
            path="/reportedhistory"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#667BBC' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <ReportHistoryIndex />
                </ProtectedRoute>
              </Suspense>
            }
          />
           <Route
            exact
            path="/reportedhistory/viewdetails/:reportId"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#667BBC' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <ViewReportedHistory />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route element={<SettingsLayout />}>
            <Route
              exact
              path="/settings"
              element={
                <Suspense
                  fallback={
                    <div>
                      <SpinnerDotted
                        style={{ marginTop: '250px', marginLeft: '300px', color: '#667BBC' }}
                      />
                    </div>
                  }
                >
                  <ProtectedRoute>
                    {' '}
                    <Settings />
                  </ProtectedRoute>
                </Suspense>
              }
            />
            <Route
              exact
              path="/preferences"
              element={
                <Suspense
                  fallback={
                    <div>
                      <SpinnerDotted
                        style={{ marginTop: '250px', marginLeft: '300px', color: '#667BBC' }}
                      />
                    </div>
                  }
                >
                  <ProtectedRoute>
                    {' '}
                    <Preferences />
                  </ProtectedRoute>
                </Suspense>
              }
            />
            <Route
              exact
              path="/api"
              element={
                <Suspense
                  fallback={
                    <div>
                      <SpinnerDotted
                        style={{ marginTop: '250px', marginLeft: '300px', color: '#667BBC' }}
                      />
                    </div>
                  }
                >
                  <ProtectedRoute>
                    {' '}
                    <SettingsApi />
                  </ProtectedRoute>
                </Suspense>
              }
            />
          </Route>
          <Route element={<ProfileLayout />}>
            <Route
              exact
              path="/profile"
              element={
                <Suspense
                  fallback={
                    <div>
                      <SpinnerDotted
                        style={{ marginTop: '250px', marginLeft: '300px', color: '#667BBC' }}
                      />
                    </div>
                  }
                >
                  <ProtectedRoute>
                    {' '}
                    <ProfilePage />
                  </ProtectedRoute>
                </Suspense>
              }
            />
            <Route
              exact
              path="/profile-edit"
              element={
                <Suspense
                  fallback={
                    <div>
                      <SpinnerDotted
                        style={{ marginTop: '250px', marginLeft: '300px', color: '#667BBC' }}
                      />
                    </div>
                  }
                >
                  <ProtectedRoute>
                    {' '}
                    <ProfileEdit />
                  </ProtectedRoute>
                </Suspense>
              }
            />
            <Route
              exact
              path="/cms"
              element={
                <Suspense
                  fallback={
                    <div>
                      <SpinnerDotted style={{ marginTop: '250px', marginLeft: '300px' }} />
                    </div>
                  }
                >
                  <ProtectedRoute>
                    {' '}
                    <Faq />
                  </ProtectedRoute>
                </Suspense>
              }
            />

            <Route
              exact
              path="/changePassword"
              element={
                <Suspense
                  fallback={
                    <div>
                      <SpinnerDotted
                        style={{ marginTop: '250px', marginLeft: '300px', color: '#667BBC' }}
                      />
                    </div>
                  }
                >
                  <ProtectedRoute>
                    {' '}
                    <ChangePassword />
                  </ProtectedRoute>
                </Suspense>
              }
            />
          </Route>
        </Route>
        <Route
          exact
          path="*"
          element={
            <Suspense
              fallback={
                <div>
                  <SpinnerDotted
                    style={{ marginTop: '250px', marginLeft: '300px', color: '#667BBC' }}
                  />
                </div>
              }
            >
              {' '}
              <PageNotFound />
            </Suspense>
          }
        />
        <Route
          exact
          path="/permission"
          element={
            <Suspense
              fallback={
                <div>
                  <SpinnerDotted
                    style={{ marginTop: '250px', marginLeft: '300px', color: '#667BBC' }}
                  />
                </div>
              }
            >
              <ProtectedRoute>
                {' '}
                <PermissionDenied />
              </ProtectedRoute>
            </Suspense>
          }
        />
      </Routes>
    </Router>
  );
}

// eslint-disable-next-line react/prop-types
function ProtectedRoute({ children }) {
  if (document.cookie && getCookies('Token')) {
    if (getCookies('USERPERMISSION')) {
      return children;
    }
    removeCookies('Token');
    removeCookies('refreshToken');
    sessionStorage.setItem('cookiesCleared', true);
    return <Navigate to="/login" />;
  }
  sessionStorage.setItem('cookiesCleared', true);
  return <Navigate to="/login" />;
}

// eslint-disable-next-line react/prop-types
function UnProtectedRoute({ children }) {
  return getCookies('Token') ? <Navigate to="/dashboard" /> : children;
}
